/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useInView } from 'react-intersection-observer'
import { is } from 'ramda'
import { oneOf, string } from 'prop-types'

const DISPLAY_NAME = 'IntroTransition'

const PROP_TYPES = {
  direction: oneOf(['down', 'left', 'none', 'right', 'up']),
  moveAmount: string,
}

const Component = ({
  children,
  direction = 'up',
  moveAmount: moveAmountProp = '8px',
}) => {
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '-12%',
    triggerOnce: true,
  })
  const moveAmount = (
    is(Number, moveAmountProp) ? `${moveAmountProp}px` : moveAmountProp
  )

  const preTranslate = {
    down: `0, -${moveAmount}`,
    left: `${moveAmount}, 0`,
    none: '0, 0',
    right: `-${moveAmount}, 0`,
    up: `0, ${moveAmount}`,
  }[direction]

  return (
    <div
      ref={ref}
      sx={{
        transition: '1000ms ease-out',
        transitionProperty: 'transform, opacity',
        transform: `translate(${inView ? '0, 0' : preTranslate})`,
        opacity: inView ? 1 : 0,
      }}
    >
      {children}
    </div>
  )
}

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
