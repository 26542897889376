/** @jsx jsx */
import { jsx } from 'theme-ui'

const DISPLAY_NAME = 'ContentLeftLayout'

const Component = ({ children, ...restProps }) => (
  <div
    sx={{ maxWidth: 'contentSmall' }}
    {...restProps}
  >
    {children}
  </div>
)

Component.displayName = DISPLAY_NAME

export default Component
