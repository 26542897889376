/** @jsx jsx */
import { jsx } from 'theme-ui'
import { number, oneOf } from 'prop-types'

const DISPLAY_NAME = 'BrandLogo'

const SCHEME_MAP = {
  Light: 'brand',
  Dark: 'textDark',
}

const PROP_TYPES = {
  height: number,
  scheme: oneOf(Object.keys(SCHEME_MAP)),
}

const Component = ({
  height = 32,
  scheme = 'Light',
  ...restProps
}) => (
  <svg
    viewBox="0 0 1967.48 1141.55"
    sx={{
      display: 'block',
      fill: SCHEME_MAP[scheme],
      transition: 'default',
      transitionProperty: 'fill',
      height,
    }}
    {...restProps}
  >
    <path d="M537.85,484.44V888.15H417.37V786.29c-29.82,67.08-109.32,111.79-203.72,111.79-127.94,0-212.41-77-212.41-188.8,0-120.49,95.65-195,243.46-195H375.13c28.57,0,42.24-14.9,42.24-37.26,0-75.78-54.66-127.94-151.55-127.94-86.95,0-150.3,54.65-154,118H0c9.94-123,121.73-214.89,269.55-214.89C431,252.16,537.85,345.32,537.85,484.44ZM417.37,613.63v-14.9H255.88c-82,0-135.39,42.22-135.39,108.06,0,57.14,47.2,95.65,116.76,95.65C351.53,802.44,417.37,729.16,417.37,613.63Z" />
    <path d="M656.76,0H777.25V888.15H656.76Z" />
    <path d="M900.53,262.1H1021v93.16c37.26-62.11,113-103.1,200-103.1,188.81,0,296.88,136.64,296.88,323s-113,323-303.09,323c-83.22,0-157.75-38.5-193.77-98.13v341.6H900.53Zm495.62,313c0-130.42-77-217.38-192.54-217.38-116.76,0-192.53,87-192.53,217.38s75.77,217.39,192.53,217.39C1319.14,792.51,1396.15,705.55,1396.15,575.12Z" />
    <path d="M1728.39,0V151.54H1594.24V0Zm-126.7,262.1h120.49V888.15H1601.69Z" />
    <path d="M1967.48,0V151.54H1833.33V0Zm-126.7,262.1h120.49V888.15H1840.78Z" />
  </svg>
)

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
