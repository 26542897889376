/** @jsx jsx */
import { jsx, Text } from 'theme-ui'
import { bool, number, oneOfType, string } from 'prop-types'

const DISPLAY_NAME = 'NumeratorDenominator'

const PROP_TYPES = {
  denominator: oneOfType([string, number]),
  numerator: oneOfType([string, number]),
  smallDenominator: bool,
  variant: string,
}

const Component = ({
  denominator,
  variant = undefined,
  numerator,
  smallDenominator = true,
}) => (
  <Text sx={{ variant }}>
    {numerator}
    <Text sx={{ fontSize: smallDenominator && '0.5em' }}>
      /
      {denominator}
    </Text>
  </Text>
)

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
