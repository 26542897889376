/** @jsx jsx */
import { bool, func, oneOf } from 'prop-types'
import { Button, jsx } from 'theme-ui'

const DISPLAY_NAME = 'MobileMenuButton'
const LINE_WIDTH = '2px'

const SCHEME_COLOR_MAP = {
  Light: 'text',
  Dark: 'textDark',
}

const PROP_TYPES = {
  active: bool,
  onClick: func,
  scheme: oneOf(Object.keys(SCHEME_COLOR_MAP)),
}

const Line = props => (
  <div
    sx={{
      height: LINE_WIDTH,
      width: '100%',
      transition: 'default',
      transitionProperty: 'background-color, opacity, transform',
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
)

const Component = ({ active = false, onClick, scheme = 'Light' }) => (
  <Button
    onClick={onClick}
    variant="padded"
  >
    <div
      sx={{
        position: 'relative',
        height: '16px',
        width: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        line: {
          backgroundColor: SCHEME_COLOR_MAP[scheme],
        },
      }}
    >
      <Line
        sx={{
          opacity: active && 0,
          backgroundColor: SCHEME_COLOR_MAP[scheme],
        }}
      />
      <div
        sx={{
          height: LINE_WIDTH,
          position: 'relative',
        }}
      >
        <Line
          sx={{
            transform: active && 'rotate(45deg)',
            backgroundColor: SCHEME_COLOR_MAP[scheme],
          }}
        />
        <Line
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            transform: active && 'rotate(-45deg)',
            backgroundColor: SCHEME_COLOR_MAP[scheme],
          }}
        />
      </div>
      <Line
        sx={{
          opacity: active && 0,
          backgroundColor: SCHEME_COLOR_MAP[scheme],
        }}
      />
    </div>
  </Button>
)

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
