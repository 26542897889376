/** @jsx jsx */
import { jsx } from 'theme-ui'
import Link from '~/components/Link'
import { LinkType } from '~/types'

type OptionsType = {
  style?: 'navigation' | 'default',
  scheme?: 'Light' | 'Dark',
}

const DEFAULT_SCHEME = 'Light'

const STYLE_MAP = {
  Light: {
    navigation: {
      Link: 'links.navigation',
      Button: 'buttons.navigation',
    },
    default: {
      Link: 'links.buttonLink',
      Button: 'buttons.primary',
      'Button Outline': 'buttons.outline',
    },
  },
  Dark: {
    navigation: {
      Link: 'links.navigationDark',
      Button: 'buttons.navigationDark',
    },
    default: {
      Link: 'links.buttonLink',
      Button: 'buttons.dark',
      'Button Outline': 'buttons.outlineDark',
    },
  },
}

const getVariant = (
  style,
  variantGroupId = 'default',
  schemeId = DEFAULT_SCHEME,
) => {
  const scheme = STYLE_MAP[schemeId || DEFAULT_SCHEME]
  const variantGroup = scheme[variantGroupId]
  return variantGroup[style] ?? variantGroup.Link
}

const renderLink = (
  { text, to, href, style }: LinkType,
  {
    style: variantGroupId = 'default',
    scheme: schemeId = DEFAULT_SCHEME,
  }: OptionsType = {},
) => {
  const variant = getVariant(style, variantGroupId, schemeId)
  return (
    <Link
      {...{ href, to }}
      sx={{ variant }}
      linkStyle={style}
    >
      {text}
    </Link>
  )
}

export default renderLink
