import { object, shape, string } from 'prop-types'

export interface ContentfulImageType {
  title: string,
  localFile: any,
}

export const ContentfulImagePropType = shape({
  title: string.isRequired,
  localFile: shape({
    childImageSharp: shape({
      // eslint-disable-next-line react/forbid-prop-types
      gatsbyImageData: object.isRequired,
    }).isRequired,
  }).isRequired,
})
