/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Fragment } from 'react'
import { oneOf } from 'prop-types'

import AnnouncementBar from '~/layout/AnnouncementBar'
import Header from '~/layout/Header'
import Footer from '~/layout/Footer'

const DISPLAY_NAME = 'Layout'

const PROP_TYPES = {
  textColorScheme: oneOf(['Light', 'Dark'])
}

interface PropTypes {
  children: any,
  textColorScheme?: 'Light' | 'Dark',
}

const Component = ({ children, textColorScheme = 'Light' }: PropTypes) => (
  <Fragment>
    <div sx={{ minHeight: '100vh' }}>
      <AnnouncementBar />
      <Header textColorScheme={textColorScheme} />
      <main>{children}</main>
    </div>
    <Footer />
  </Fragment>
)

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
