/** @jsx jsx */
import { useState } from 'react'
import { Container, jsx } from 'theme-ui'
import { graphql, useStaticQuery } from 'gatsby'

import { oneOf } from 'prop-types'
import BrandLogo from '~/components/BrandLogo'
import Link from '~/components/Link'
import MobileMenuButton from '~/components/MobileMenuButton'
import MobileMenu from '~/layout/MobileMenu'
import renderLink from '~/content/renderLink'
import { LinkType } from '~/types'

const DISPLAY_NAME = 'Header'

const QUERY = graphql`
  query {
    header: contentfulHeader {
      desktopLeftLinks { ...Link }
      desktopRightLinks { ...Link }
      mobileLinks { id }
    }
  }
`

const PROP_TYPES = {
  textColorScheme: oneOf(['Light', 'Dark']),
}

interface PropTypes {
  textColorScheme: 'Light' | 'Dark'
}

interface HeaderType {
  desktopLeftLinks: LinkType[],
  desktopRightLinks: LinkType[],
  mobileLinks: LinkType[],
}

const Component = ({ textColorScheme = 'Light' }: PropTypes) => {
  const { header } = useStaticQuery(QUERY)
  const { desktopLeftLinks, desktopRightLinks, mobileLinks }: HeaderType = header
  const isMobileLinks = mobileLinks?.length
  const [mobileMenuActive, setMobileMenuActive] = useState(false)
  const textColorSchemeOverride = (
    mobileMenuActive
      ? 'Light'
      : (textColorScheme || 'Light')
  )

  const handleMenuButtonCick = () => setMobileMenuActive(!mobileMenuActive)
  const handleCloseMenu = () => setMobileMenuActive(false)

  return (
    <header
      role="banner"
      sx={{
        alignItems: 'center',
        display: 'flex',
        height: ['headerMobile', null, 'headerDesktop'],
        position: 'relative',
        zIndex: 'header',
      }}
    >
      <div
        sx={{
          zIndex: 1,
          width: '100%',
        }}
      >
        <Container variant="containerFullWidth">
          <nav>
            <div
              sx={{
                display: 'grid',
                gridTemplateAreas: [
                  !isMobileLinks ? '"brand . cta"' : '"mobileMenuButton brand . cta"',
                  null,
                  !isMobileLinks ? null : '"brand nav . cta"',
                ],
                gridTemplateColumns: [
                  !isMobileLinks ? 'auto 1fr auto' : 'auto auto 1fr auto',
                  null,
                  !isMobileLinks ? null : 'repeat(2, auto) 1fr auto',
                ],
                columnGap: [1, null, 4],
                alignItems: 'center',
              }}
            >
              {isMobileLinks && (
                <div
                  sx={{
                    display: [null, null, 'none'],
                    gridArea: 'mobileMenuButton',
                  }}
                >
                  <MobileMenuButton
                    onClick={handleMenuButtonCick}
                    active={mobileMenuActive}
                    scheme={textColorSchemeOverride}
                  />
                </div>
              )}
              <div sx={{ gridArea: 'brand' }}>
                <Link
                  to="/"
                  sx={{ variant: 'links.padded' }}
                >
                  <BrandLogo scheme={textColorSchemeOverride} />
                </Link>
              </div>
              {desktopLeftLinks?.length && (
                <div
                  sx={{
                    display: ['none', null, 'block'],
                    gridArea: [null, null, 'nav'],
                  }}
                >
                  <ul
                    sx={{
                      display: 'grid',
                      gridAutoFlow: 'column',
                      columnGap: 3,
                      alignItems: 'center',
                    }}
                  >
                    {desktopLeftLinks.map(({ id, ...link }) => (
                      <li key={id}>
                        {
                          renderLink(
                            link,
                            {
                              style: 'navigation',
                              scheme: textColorSchemeOverride,
                            },
                          )
                        }
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {desktopRightLinks?.length && (
                <div
                  sx={{
                    gridArea: 'cta',
                    justifySelf: 'end',
                  }}
                >
                  <ul
                    sx={{
                      display: 'grid',
                      gridAutoFlow: 'column',
                      columnGap: [1, 2, 3],
                      alignItems: 'center',
                    }}
                  >
                    {desktopRightLinks.map(({ id, ...link }) => (
                      <li key={id}>
                        {
                          renderLink(
                            link,
                            {
                              style: 'navigation',
                              scheme: textColorSchemeOverride,
                            },
                          )
                        }
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </nav>
        </Container>
      </div>
      {isMobileLinks && (
        <MobileMenu
          active={mobileMenuActive}
          onCloseMenu={handleCloseMenu}
        />
      )}
    </header>
  )
}

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
