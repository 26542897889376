/** @jsx jsx */
import { graphql, useStaticQuery } from 'gatsby'
import { jsx, Text } from 'theme-ui'
import Link from '~/components/Link'

const DISPLAY_NAME = 'AnnouncementBar'

const QUERY = graphql`
  query {
    announcementBar: contentfulAnnouncementBar {
      href
      show
      text
      to
    }
  }
`

const Component = () => {
  const { announcementBar = {} } = useStaticQuery(QUERY)
  const { href, show, text, to } = announcementBar ?? {}
  if (!show) return null

  const content = (
    <div
      sx={{
        height: '34px',
        backgroundColor: 'brand',
        color: 'textDark',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'default',
        transitionProperty: 'background-color',
      }}
    >
      <Text>
        {text}
      </Text>
    </div>
  )

  return (href || to) ? (
    <Link
      {...{ href, to }}
      newTab={!!href}
      sx={{
        textDecoration: 'none',
        ':hover > div': {
          backgroundColor: 'brandInteraction',
        },
      }}
    >
      {content}
    </Link>
  ) : content
}

Component.displayName = DISPLAY_NAME

export default Component
