import React from 'react'
import { bool, string } from 'prop-types'
import { Button, Link as ThemeUILink } from 'theme-ui'
import { Link as GatsbyLink } from 'gatsby'

const PROP_TYPES = {
  href: string,
  linkStyle: string,
  newTab: bool,
  to: string,
}

const Component = ({
  to = undefined,
  href = undefined,
  newTab = false,
  // eslint-disable-next-line no-unused-vars
  linkStyle = undefined,
  ...restProps
}) => {
  if (href) {
    return (
      <ThemeUILink
        target={newTab ? '_blank' : undefined}
        rel={newTab ? 'noopener' : undefined}
        href={href}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...restProps}
      />
    )
  }

  const isSectionLink = to.charAt(0) === '#'

  if (isSectionLink) {
    const handleSectionLinkClick = () => {
      const id = to.substring(1)
      const element = document.getElementById(id)
      if (!element) return
      element.scrollIntoView({ behavior: 'smooth' })
    }
    return (
      <Button
        onClick={handleSectionLinkClick}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...restProps}
      />
    )
  }

  return (
    <GatsbyLink
      to={to}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
    />
  )
}

Component.propTypes = PROP_TYPES

export default Component
