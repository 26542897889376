/** @jsx jsx */
import { Heading, jsx, Text } from 'theme-ui'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'

enum Styles {
  small = 'small',
  normal = 'normal',
  prospect = 'prospect',
}

type OptionsType = {
  style?: keyof typeof Styles
}

const createOptions = (style = 'normal') => ({
  renderNode: {
    [BLOCKS.DOCUMENT]: (_, children) => (
      <div
        sx={{
          'h1, h2': {
            marginTop: '2em',
          },
          'h3, h4': {
            marginTop: '1.5em',
          },
          p: {
            marginTop: '0.7em',
          },
          'h1 + h2, h1 + h3, h1 + h4, h2 + h3, h2 + h4, h3 + h4': {
            marginTop: '1em',
          },
        }}
      >
        {children}
      </div>
    ),
    [BLOCKS.HEADING_1]: (_, children) => (
      <Heading
        as="h1"
        variant={{
          [Styles.prospect]: 'heading1',
          [Styles.normal]: 'heading2',
          [Styles.small]: 'heading3',
        }[style]}
      >
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_2]: (_, children) => (
      <Heading
        as="h2"
        variant={{
          [Styles.prospect]: 'heading2',
          [Styles.normal]: 'heading3',
          [Styles.small]: 'heading4',
        }[style]}
      >
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_3]: (_, children) => (
      <Heading
        as="h3"
        variant={{
          [Styles.prospect]: 'heading3',
          [Styles.normal]: 'heading4',
          [Styles.small]: 'heading5',
        }[style]}
      >
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_4]: (_, children) => (
      <Heading
        as="h4"
        variant={{
          [Styles.prospect]: 'heading4',
          [Styles.normal]: 'heading5',
          [Styles.small]: 'heading6',
        }[style]}
      >
        {children}
      </Heading>
    ),
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Text
        as="p"
        variant={{
          [Styles.prospect]: 'prospectBody',
          [Styles.normal]: 'body',
          [Styles.small]: 'small',
        }[style]}
      >
        {children}
      </Text>
    ),
    [BLOCKS.UL_LIST]: (_, children) => (
      <ul
        sx={{
          listStyleType: 'disc',
          paddingLeft: '2em',
          marginTop: '0.7rem',
        }}
      >
        {children}
      </ul>
    ),
  },
})

export default (richText, options?: OptionsType) => {
  if (!richText?.raw) return null
  const document = JSON.parse(richText.raw)
  return documentToReactComponents(document, createOptions(options?.style))
}
