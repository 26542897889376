/** @jsx jsx */
import { useRef } from 'react'
import { jsx } from 'theme-ui'
import { bool, number, shape, string } from 'prop-types'

const DISPLAY_NAME = 'SilentVideo'

const PROP_TYPES = {
  loop: bool,
  loopStart: number,
  loopEnd: number,
  poster: string.isRequired,
  src: shape({
    src: string,
    type: string,
  }).isRequired,
}

const Component = ({
  loop = true,
  loopEnd = undefined,
  loopStart = undefined,
  poster,
  src,
  ...restProps
}) => {
  const ref = useRef()

  const startFromLoopStart = () => {
    if (!ref.current) return
    const videoEl = ref.current as HTMLVideoElement
    videoEl.currentTime = loopStart ?? 0
    videoEl.play()
  }

  const handleTimeUpdate = event => {
    if (!loopEnd || !loop) return
    if (event.target.currentTime < loopEnd) return
    startFromLoopStart()
  }

  const handleEnded = () => {
    if (!loop) return
    startFromLoopStart()
  }

  return (
    <video
      autoPlay
      loop={!loopStart && !loopEnd && loop}
      muted
      onEnded={handleEnded}
      onTimeUpdate={handleTimeUpdate}
      playsInline
      poster={poster}
      ref={ref}
      sx={{ width: '100%' }}
      {...restProps}
    >
      <source
        src={src.src}
        type={src.type}
      />
    </video>
  )
}

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
