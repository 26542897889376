/** @jsx jsx */
import { Container, jsx } from 'theme-ui'
import { graphql, useStaticQuery } from 'gatsby'

import Link from '~/components/Link'
import BrandLogo from '~/components/BrandLogo'
import renderLink from '~/content/renderLink'
import { LinkType, SocialMediaAccountType } from '~/types'

const DISPLAY_NAME = 'Footer'

const QUERY = graphql`
  query {
    footer: contentfulFooter {
      socialMediaLinks { ...SocialMediaAccount }
      navigationLinks { ...Link }
    }
  }
`

const desktopStyle = areaName => [null, null, areaName]

const LinksDivider = () => (
  <li sx={{ display: ['none', null, 'block'] }}>
    <div
      sx={{
        width: '1px',
        height: '2ex',
        backgroundColor: 'text',
      }}
    />
  </li>
)

interface FooterType {
  socialMediaLinks: SocialMediaAccountType[],
  navigationLinks: LinkType[],
}

const Component = () => {
  const { footer } = useStaticQuery(QUERY)
  const { socialMediaLinks, navigationLinks }: FooterType = footer
  return (
    <footer
      sx={{
        paddingTop: 4,
        paddingBottom: 2,
      }}
    >
      <Container variant="containerFullWidth">
        <div
          sx={{
            display: 'grid',
            gridTemplateAreas: [null, null, `
              "brand ."
              "copyright links"
            `],
            columnGap: '1',
            rowGap: '2',
          }}
        >
          <div
            sx={{
              gridArea: desktopStyle('brand'),
              justifySelf: 'start',
            }}
          >
            <Link
              to="/"
              sx={{ variant: 'links.padded' }}
            >
              <BrandLogo />
            </Link>
          </div>
          <div sx={{ gridArea: desktopStyle('copyright') }}>
            ©
            {' '}
            {new Date().getFullYear()}
            {' '}
            Alpii LLC
          </div>
          <div
            sx={{
              gridArea: desktopStyle('links'),
              justifySelf: [null, null, 'end'],
            }}
          >
            <ul
              sx={{
                alignItems: desktopStyle('center'),
                display: 'grid',
                gap: ['1', null, '2'],
                gridAutoFlow: desktopStyle('column'),
                justifyItems: ['start', null, 'unset'],
              }}
            >
              {socialMediaLinks.map(({ id, name, url }) => (
                <li key={id}>
                  <Link
                    href={url}
                    newTab
                    sx={{ variant: 'links.navigation' }}
                  >
                    {name}
                  </Link>
                </li>
              ))}
              {socialMediaLinks && navigationLinks && (
                <LinksDivider />
              )}
              {navigationLinks.map(({ id, ...link }) => (
                <li key={id}>
                  {renderLink(link, { style: 'navigation' })}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Container>
    </footer>
  )
}

Component.displayName = DISPLAY_NAME

export default Component
