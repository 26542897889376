/** @jsx jsx */
import { Fragment } from 'react'
import { jsx } from 'theme-ui'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'

import SilentVideo from '~/components/SilentVideo'
import convertTypename from '~/utilities/convertTypename'
import { MediaPropType, MediaType, MediaBreakpointType, MediaBreakpointPropType } from '~/types'

const DISPLAY_NAME = 'Media'

interface MediaBreakpointPropTypes {
  media: MediaBreakpointType
}

const MediaBreakpoint = ({
  media: {
    __typename: typename,
    ...media
  },
}: MediaBreakpointPropTypes) => {
  const mediaType = convertTypename(typename)
  const imageFile = media?.image?.localFile
  const imageAlt = media?.image?.title
  const posterFile = media?.poster?.localFile
  const videoFile = media?.video?.localFile

  return {
    Image: () => (
      <GatsbyImage
        image={getImage(imageFile)}
        alt={imageAlt}
      />
    ),
    SilentVideo: () => (
      <SilentVideo
        poster={getSrc(posterFile)}
        src={{
          src: videoFile?.publicURL,
          type: videoFile?.internal?.mediaType,
        }}
      />
    ),
  }[mediaType]?.() ?? null
}

MediaBreakpoint.propTypes = {
  media: MediaBreakpointPropType.isRequired,
}

interface PropTypes {
  media: MediaType
}

const Component = ({
  media: {
    media,
    mediaLarge,
  },
}: PropTypes) => (
  <Fragment>
    <div
      sx={{
        display: [null, 'none'],
        width: '100%',
      }}
    >
      <MediaBreakpoint media={media} />
    </div>
    <div
      sx={{
        display: ['none', 'block'],
        width: '100%',
      }}
    >
      <MediaBreakpoint media={mediaLarge || media} />
    </div>
  </Fragment>
)

Component.propTypes = {
  media: MediaPropType.isRequired,
}

Component.displayName = DISPLAY_NAME

export default Component
