/** @jsx jsx */
import { bool, object, oneOf } from 'prop-types'
import { Fragment } from 'react'
import { jsx, useThemeUI } from 'theme-ui'
import { GatsbyImage } from 'gatsby-plugin-image'

const DISPLAY_NAME = 'Section'

const TEXT_COLOR_SCHEME_MAP = {
  Dark: 'textDark',
}

const BackgroundImage = ({ image, children }) => (
  <Fragment>
    <GatsbyImage
      alt=""
      aria-hidden
      draggable={false}
      image={image}
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    />
    <div sx={{ paddingTop: 'var(--section-padding)' }}>
      {children}
    </div>
  </Fragment>
)

BackgroundImage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  image: object,
}

const PROP_TYPES = {
  belowHeader: bool,
  // eslint-disable-next-line react/forbid-prop-types
  backgroundImage: object,
  textColorScheme: oneOf(['Light', 'Dark']),
}

const Component = ({
  backgroundImage = undefined,
  belowHeader = false,
  children,
  textColorScheme = 'Light',
  ...restProps
}) => {
  const { theme: { space } } = useThemeUI()

  const commonStyles = {
    position: backgroundImage && 'relative',
    color: TEXT_COLOR_SCHEME_MAP[textColorScheme],
  }

  const belowHeaderStyles = {
    ...commonStyles,
    '--header-height': [space.headerMobile, null, space.headerDesktop],
    '--section-padding': `calc(var(--header-height) + ${space[5]})`,
    paddingTop: !backgroundImage && 'var(--section-padding)',
    paddingBottom: 6,
    marginTop: 'calc(var(--header-height) * -1)',
  }

  const defaultStyles = {
    ...commonStyles,
    paddingY: 6,
  }

  const sx = belowHeader ? belowHeaderStyles : defaultStyles

  return (
    <section {...{ sx, ...restProps }}>
      {backgroundImage ? (
        <BackgroundImage image={backgroundImage}>
          {children}
        </BackgroundImage>
      ) : children}
    </section>
  )
}

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
