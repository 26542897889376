import { oneOf, shape, string } from 'prop-types'

export interface LinkType {
  href?: string,
  id?: string,
  style?: 'Link' | 'Button',
  text: string,
  to?: string,
}

export const LinkPropType = shape({
  href: string,
  id: string,
  style: oneOf(['Link', 'Button', 'Button Outline']),
  text: string.isRequired,
  to: string,
})
