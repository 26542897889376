import { Events } from './events'

declare global {
  interface Window {
    gtag: any
  }
}

const gtag = (...args) => window?.gtag?.(...args)

const useTracking = () => {
  const trackEvent = (event: Events, params: object) => {
    gtag('event', event, params)
  }
  return { trackEvent }
}

export default useTracking
