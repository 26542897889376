/** @jsx jsx */
import { useEffect, useRef } from 'react'
import { Container, jsx } from 'theme-ui'
import { bool, func } from 'prop-types'
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'
import { graphql, useStaticQuery } from 'gatsby'
import { useLocation } from '@reach/router'
import { useResponsiveValue } from '@theme-ui/match-media'

import Link from '~/components/Link'

const DISPLAY_NAME = 'MobileMenu'

const QUERY = graphql`
  query {
    header: contentfulHeader {
      mobileLinks {
        ...Link
      }
    }
  }
`

const PROP_TYPES = {
  active: bool,
  onCloseMenu: func,
}

const Component = ({
  active = false,
  onCloseMenu: closeMenu = () => {},
}) => {
  const { pathname } = useLocation()
  const { header } = useStaticQuery(QUERY)
  const { mobileLinks } = header
  const ref = useRef()
  const breakpointActive = useResponsiveValue([true, true, false])

  const handleLinkClick = event => {
    const { pathname: linkPathname } = new URL(event.target.href)
    if (linkPathname !== pathname) return
    closeMenu()
  }

  useEffect(() => {
    if (active) {
      window.scrollTo({
        top: 0,
        behavior: 'instant',
      })
      disableBodyScroll(ref.current)
      return
    }
    enableBodyScroll(ref.current)
  }, [active])

  useEffect(() => {
    if (breakpointActive) return
    closeMenu()
  }, [breakpointActive, closeMenu])

  return (
    <div
      aria-hidden={!active}
      ref={ref}
      sx={{
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        height: [active ? '100vh' : 0, null, 0],
        transition: 'default',
        transitionProperty: 'height',
        overflow: 'hidden',
        backgroundColor: 'background',
      }}
    >
      <div sx={{ paddingTop: 'headerMobile' }}>
        <Container>
          <ul
            sx={{
              marginTop: 3,
              borderTopWidth: '1px',
              borderTopStyle: 'solid',
              borderTopColor: 'textDarkGrey',
            }}
          >
            {mobileLinks.map(({ href, text, to }) => (
              <li key={to + href}>
                <Link
                  to={to}
                  href={href}
                  onClick={handleLinkClick}
                  sx={{
                    display: 'block',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    paddingLeft: 1,
                    paddingRight: 1,
                    fontSize: 'prospectBody',
                    textDecoration: 'none',
                    ':first-of-type': {
                      borderBottomWidth: '1px',
                      borderBottomStyle: 'solid',
                      borderBottomColor: 'textDarkGrey',
                    },
                  }}
                >
                  {text}
                </Link>
              </li>
            ))}
          </ul>
        </Container>
      </div>
    </div>
  )
}

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
