/** @jsx jsx */
import { Grid, Input, Button, Label, Text, jsx } from 'theme-ui'
import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { bool, string } from 'prop-types'
import { useTracking, Events } from '~/utilities/tracking'

const DISPLAY_NAME = 'EmailSubscribeForm'

const PROP_TYPES = {
  errorText: string,
  focusOnLoad: bool,
  successText: string,
}

const Component = ({
  errorText = 'There was an error. Please try again later.',
  focusOnLoad = false,
  successText = 'Thanks for signing up!',
}) => {
  const [showForm, setShowForm] = useState(true)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [formError, setFormError] = useState()
  const [formDisabled, setFormDisabled] = useState(false)
  const { register, handleSubmit, formState: { errors }, setFocus } = useForm()
  const { trackEvent } = useTracking()

  const handleValidatedSubmit = async ({ email }) => {
    setFormDisabled(true)
    trackEvent(Events.JOIN_WAITLIST, { email })
    try {
      const response = await fetch('/.netlify/functions/email-subscribe', {
        method: 'POST',
        body: JSON.stringify({ email }),
      })
      if (!response.ok) {
        throw new Error(errorText)
      }
    } catch (error) {
      setFormError(error)
      setFormDisabled(false)
      return
    }
    setShowForm(false)
  }

  useEffect(() => {
    if (showForm) return
    setTimeout(() => {
      setShowSuccessMessage(true)
    }, 400)
  }, [showForm])

  useEffect(() => {
    if (!focusOnLoad) return
    setFocus('email')
  }, [focusOnLoad, setFocus])

  return (
    <div sx={{ position: 'relative' }}>
      <div
        aria-hidden={!showForm}
        sx={{
          transition: 'opacity 400ms ease-out',
          opacity: showForm ? 1 : 0,
          pointerEvents: showForm ? null : 'none',
        }}
      >
        <form
          onSubmit={handleSubmit(handleValidatedSubmit)}
          noValidate
        >
          <Grid
            sx={{
              alignItems: 'start',
              columnGap: [null, null, 1],
              gridAutoFlow: [null, null, 'column'],
              gridTemplateColumns: [null, null, '1fr auto'],
              rowGap: 1,
            }}
          >
            <div>
              <Label
                variant="accessibility.srOnly"
                htmlFor="signup-email"
              >
                Email
              </Label>
              <Input
                id="signup-email"
                type="email"
                placeholder="Email"
                variant={errors.email ? 'error' : 'input'}
                disabled={formDisabled}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('email', {
                  required: 'Please enter a valid email.',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Please enter a valid email.',
                  },
                })}
              />
              {errors.email && (
                <Text
                  as="div"
                  variant="formErrorMessage"
                >
                  {errors.email.message}
                </Text>
              )}
            </div>
            <Button
              type="submit"
              variant="primary"
            >
              Join the Waitlist
            </Button>
          </Grid>
          {formError && (
            <Text
              as="div"
              variant="formErrorMessage"
            >
              {formError.message}
            </Text>
          )}
        </form>
      </div>
      <div
        aria-hidden={!showSuccessMessage}
        sx={{
          transition: 'opacity 400ms ease-out',
          opacity: showSuccessMessage ? 1 : 0,
          pointerEvents: showSuccessMessage ? null : 'none',
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Text variant="prospectBody">
          {successText}
        </Text>
      </div>
    </div>
  )
}

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
