import React from 'react'
import { string, arrayOf, shape } from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

interface PropTypes {
  description?: string,
  lang?: string,
  meta?: {
    name?: string,
    property?: string,
    content: string,
  }[],
  title?: string,
}

const PROP_TYPES = {
  description: string,
  lang: string,
  meta: arrayOf(shape({
    name: string,
    property: string,
    content: string.isRequired,
  })),
  title: string,
}

const Seo = ({
  description,
  lang = 'en',
  meta = [],
  title: pageTitle,
}: PropTypes) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const displayTitle = pageTitle ? `${pageTitle} - ${defaultTitle}` : defaultTitle

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={displayTitle}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: pageTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata?.author || '',
        },
        {
          name: 'twitter:title',
          content: pageTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        ...meta,
      ]}
    />
  )
}

Seo.propTypes = PROP_TYPES

export default Seo
