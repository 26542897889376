/** @jsx jsx */
import { Children } from 'react'
import { jsx } from 'theme-ui'

const DISPLAY_NAME = 'ButtonList'

const checkElements = children => {
  const linkStyles = Children.map(children, child => {
    if (child.type !== 'li') {
      // eslint-disable-next-line no-console
      console.warn('`ButtonList` should only have `li` child elements.')
    }
    return Children.map(child.props.children, link => link.props.linkStyle)
  })
  const allAreLink = linkStyles?.every(linkStyle => linkStyle === 'Link')
  return { allAreLink }
}

const Component = ({ children, ...restProps }) => {
  const { allAreLink } = checkElements(children)
  return (
    <ul
      sx={{
        alignItems: [allAreLink ? 'flex-start' : 'stretch', 'center'],
        display: 'flex',
        flexDirection: [allAreLink ? 'row' : 'column', 'row'],
        gap: [allAreLink ? 2 : 1, 2],
        justifyContent: ['center', 'flex-start'],
      }}
      {...restProps}
    >
      {children}
    </ul>
  )
}

Component.displayName = DISPLAY_NAME

export default Component
