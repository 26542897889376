import { bool, object, oneOf, shape, string } from 'prop-types'

export interface MediaBreakpointType {
  __typename: 'ContentfulSilentVideo' | 'ContentfulImage',
  image?: {
    title: string,
    localFile: any,
  },
  loop?: boolean,
  poster?: {
    localFile: any,
  },
  video?: {
    localFile: {
      publicURL: string,
      internal: {
        mediaType: string,
      },
    },
  },
}

export const MediaBreakpointPropType = shape({
  __typename: oneOf(['ContentfulSilentVideo', 'ContentfulImage']),
  image: shape({
    title: string.isRequired,
    localFile: shape({
      childImageSharp: shape({
        // eslint-disable-next-line react/forbid-prop-types
        gatsbyImageData: object.isRequired,
      }).isRequired,
    }).isRequired,
  }),
  loop: bool,
  poster: shape({
    localFile: shape({
      childImageSharp: shape({
        // eslint-disable-next-line react/forbid-prop-types
        gatsbyImageData: object.isRequired,
      }).isRequired,
    }).isRequired,
  }),
  video: shape({
    localFile: shape({
      publicURL: string.isRequired,
      internal: shape({
        mediaType: string.isRequired,
      }).isRequired,
    }).isRequired,
  }),
})
export interface MediaType {
  media: MediaBreakpointType,
  mediaLarge?: MediaBreakpointType,
}

export const MediaPropType = shape({
  media: MediaBreakpointPropType.isRequired,
  mediaLarge: MediaBreakpointPropType,
})
